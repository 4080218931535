.time-setter-container {
    background-color: #106EBE;
    background-image: linear-gradient(135deg ,#1E3D65 , #106EBE);
    text-align: center;
    min-height: 100vh;
    color: white;
    justify-items: center;
}

.time-setter-container h2{
    color: white;
    font-size: 24px;
}

.time-inputs{
    margin-top: 10px;
}

    .time-setter h2 {
        font-size: 24px;
    }

    .time-setter nav ul {
        list-style: none;
        padding: 0;
    }

        .time-setter nav ul li {
            margin: 5px 0;
        }

    .time-setter .timesetter-content {
        margin-top: 10px;
    }
