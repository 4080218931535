/* Sekcja Job Details */
.job-details-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 0;
}

.job-details-form{
    display: flex;
    flex-direction: column;
    background-color: #106EBE;
    background-image: linear-gradient(135deg ,#1E3D65 , #106EBE);
    min-height: 100vh;
    width: 100%;
    padding: 0;
}

    /* Tytuł wewnątrz sekcji */
    .job-details-section h2 {
        font-size: 32px;
        margin-bottom: 20px;
        color: white; /* Kolor tekstu */
        text-align: center;
    }

/* Formularz w sekcji */
form {
    width: 100%;
}

label {
    display: block;
    text-align: left;
    font-weight: bold;
    margin-bottom: 5px;
    margin-left: 10px;
    color: white; /* Kolor etykiet */
}

input, textarea {
    width: 80%; /* Pełna szerokość */
    padding: 10px; /* Wewnętrzne odstępy */
    border: none;
    border-radius: 5px; /* Zaokrąglenie */
    font-size: 1rem; /* Rozmiar czcionki */
    margin-bottom: 15px; /* Odstępy między polami */
    background-color: white; /* Kolor tła pól */
    color: black; /* Kolor tekstu */
    margin-left: 10px;
}

textarea {
    resize: vertical; /* Możliwość zmiany rozmiaru tylko w pionie */
}


/* Responsywność */
@media (max-width: 768px) {
    .job-details-section {
        padding: 15px; /* Mniejsze odstępy na mniejszych ekranach */
    }

    .job-details-form h2 {
        font-size: 24px; /* Zmniejszenie rozmiaru tytułu */
    }

    input, textarea {
        font-size: 0.9rem; /* Dopasowanie rozmiaru czcionki */
        padding: 8px; /* Mniejsze odstępy */
    }

    form {
        gap: 10px; /* Mniejsze odstępy między polami formularza */
    }
}

@media (max-width: 480px) {
    .job-details-section {
        max-width: 90%; /* Dopasowanie szerokości do małych ekranów */
    }

    .job-details-form h2 {
        font-size: 20px; /* Jeszcze mniejszy tytuł na bardzo małych ekranach */
    }

    input, textarea {
        font-size: 0.8rem; /* Zmniejszony tekst */
        padding: 6px; /* Zredukowane odstępy wewnętrzne */
    }
}
