.navbar {
    display: flex;
    justify-content: space-between; /* Sekcje są rozmieszczone na przeciwnych końcach */
    align-items: center; /* Wyrównanie elementów w pionie */
    background-color: #1E3D6588;
    padding: 10px 15px;
    color: white;
    position: sticky;
    top: 0;
    width: 100%; /* Pełna szerokość okna przeglądarki */
    margin: 0;
    box-sizing: border-box;
}

.navbar-left {
    display: flex; /* Ustawienie Flexbox dla lewej sekcji */
    align-items: center; /* Wyrównanie elementów w pionie */
    justify-content: flex-start; /* Wyrównanie do lewej strony */
    gap: 10px; /* Odstęp między elementami */
}

.navbar-right {
    display: flex; /* Ustawienie Flexbox dla prawej sekcji */
    justify-content: flex-end; /* Wyrównanie do prawej strony */
    align-items: center; /* Wyrównanie elementów w pionie */
    gap: 10px;
}

/* Styl dla przycisku Login/Logout */
.login-button, .back-button, .portal-button, .next-button {
    background-color: #0FFCB3;
    color: black;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 0.9rem; /* Rozmiar czcionki */
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
}

    .login-button:hover, .back-button:hover, .portal-button:hover, .next-button:hover {
        background-color: #106EBE; /* Niebieskie tło przy najechaniu */
        color: white; /* Biały tekst */
        transform: scale(1.05); /* Subtelne powiększenie */
    }

    .login-button:focus, .back-button:focus, .portal-button:focus, .next-button:focus {
        outline: none;
        box-shadow: 0 0 5px #0FFCB3; /* Efekt glow */
    }

.welcome-message {
    font-size: 1rem;
    color: white;
    font-weight: bold;
    text-align: center;
}

.register-button {
    background-color: #0FFCB3; /* Kolor tła */
    color: black; /* Kolor tekstu */
    border: none;
    padding: 10px 15px; /* Odstęp wewnętrzny */
    border-radius: 5px; /* Zaokrąglenie rogów */
    font-size: 0.9rem; /* Rozmiar czcionki */
    cursor: pointer;
    font-weight: bold; /* Pogrubienie tekstu */
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease; /* Efekty przejścia */
}

    .register-button:hover {
        background-color: #106EBE; /* Kolor tła po najechaniu */
        color: white; /* Kolor tekstu po najechaniu */
        transform: scale(1.05); /* Subtelne powiększenie */
    }

    .register-button:focus {
        outline: none; /* Usuń domyślny outline */
        box-shadow: 0 0 5px #0FFCB3; /* Efekt glow */
    }
.step-title {
    text-align: center;
    margin-bottom: 20px;
}

    .step-title h2 {
        font-size: 24px;
        color: #00c8ff;
        margin-bottom: 5px;
    }

    .step-title p {
        font-size: 16px;
        color: #ccc;
    }

/* Responsywność */
@media (max-width: 768px) {
    .navbar {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row; /* Nadal układ poziomy */
        width: 100%;
        align-items: normal;
    }

    .navbar-left {
        justify-content: flex-start; /* Sekcja pozostaje wyrównana do lewej */
        flex-wrap: wrap; /* Pozwala elementom zawijać się w razie potrzeby */
        gap: 10px;
    }

    .navbar-right {
        justify-content: flex-end; /* Sekcja pozostaje wyrównana do prawej */
        flex-wrap: wrap;
    }

    .login-button, .back-button, .portal-button, .next-button, .register-button {
        width: auto; /* Przycisk dostosowuje się do zawartości */
    }
}


@media (max-width: 480px) {
    .navbar-left {
        justify-content: flex-start; /* Sekcja pozostaje wyrównana do lewej */
        flex-wrap: wrap; /* Pozwala elementom zawijać się w razie potrzeby */
        gap: 2px;
    }

    .navbar-right {
        justify-content: flex-end; /* Sekcja pozostaje wyrównana do prawej */
        flex-wrap: wrap;
        gap: 2px;
    }

    .portal-button{
        margin-left: -2px;
        margin-right: -5px;
    }

    .navbar-left button, .navbar-right button{
        font-size: 0.80rem;
        font-weight: bolder;
    }
}