.progress-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    overflow-x: hidden; /* Prevent horizontal overflow */
}

.progress-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%; /* Remove fixed width */
    padding: 10px 0;
    overflow-x: hidden; /* Prevent overflow */
    flex-wrap: wrap; /* Allow wrapping if needed */
}

.progress-step {
    flex: 1; /* Equal space for each step */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #e0e0e0;
    border-radius: 50px;
    padding: 10px 5px;
    color: #555;
    font-weight: bold;
    transition: all 0.3s ease;
    margin: 0 5px; /* Reduce horizontal spacing */
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    min-width: 50px; /* Ensure steps do not shrink too much */
}

    .progress-step:last-child {
        margin-right: 0;
    }

    .progress-step.active {
        background: linear-gradient(90deg, #007bff, #0056b3);
        color: white;
        box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
        transform: scale(1.1);
    }

    .progress-step span {
        font-size: 0.7rem; /* Adjust text size */
        text-align: center;
        overflow: hidden; /* Prevent text overflow */
        white-space: nowrap; /* Avoid wrapping */
        text-overflow: ellipsis; /* Add ellipsis for long text */
    }

@media screen and (max-width: 768px) {
    .progress-bar {
        justify-content: space-evenly;
        padding: 5px 0;
    }

    .progress-step {
        padding: 8px 5px;
        margin: 0 3px;
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 480px) {
    .progress-bar {
        justify-content: space-evenly;
        padding: 5px;
    }

    .progress-step {
        padding: 6px 3px;
        font-size: 0.7rem;
        min-width: 40px;
    }

        .progress-step span {
            font-size: 0.6rem;
        }
}
