

/* Sekcja generatora */
.estimate-generator {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 70%;
    width: 70%;
    padding: 20px;
    text-align: center;
    background-color: #106EBE; /* Dopasowany kolor */
    border-radius: 10px;
    margin-bottom: 20px;
}

.estimate-title {
    font-size: 32px;
    color: white !important; /* Kolor akcentowy */
    margin-bottom: 20px;
    text-align: center;
}

.estimate-instruction {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
    color: #ccc; /* Subtelny kolor instrukcji */
}

/* Pole tekstowe */
.estimate-input {
    width: 100%;
    height: 100px;
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    resize: vertical;
    background-color: white; /* Kolor tła pola */
    color: black; /* Kolor tekstu */
}

    .estimate-input::placeholder {
        color: #ccc; /* Kolor tekstu zastępczego */
    }


/* Przyciski */
.generate-button {
    background-color: #0FFCB3;
    color: red;
    padding: 20px 150px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

    .generate-button:hover {
        background-color: black;
        color: white;
        transform: scale(1.05); /* Subtelne powiększenie przy najechaniu */
    }

.proceed-button {
    background-color: #0FFCB3;
    color: green;
    padding: 20px 200px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

    .proceed-button:hover {
        background-color: black;
        color: white;
        transform: scale(1.05);
    }
