/* Container for the entire login component */
.login-container {
    /*max-width: 400px; */
    margin: 0;
    padding: 0;
    background-color: #106EBE;
    background-image: linear-gradient(135deg ,#1E3D65 , #106EBE);
    text-align: center;
    min-height: 100vh;
    color: white;
    display: flex;
    flex-direction: column;
    justify-items: center;
}

.login-form{
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.login-form input, .login-form label{
    margin-left: 0;
    width: 100%;
}
    /* Title styling */
    .login-container h2 {
        font-size: 1.8rem;
        color: #0FFCB3; /* Dopasowanie koloru tytułu */
        margin-bottom: 1rem;
    }

    /* Form layout */
    .login-container form {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    /* Label styling */
    .login-container label {
        font-size: 1rem;
        color: #0FFCB3; /* Dopasowanie koloru etykiet */
        text-align: left;
        margin-bottom: 0.5rem;
        font-weight: 600;
    }

    /* Input field styling */
    .login-container input {
        padding: 10px;
        font-size: 1rem;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 1rem;
        box-sizing: border-box;
        transition: border-color 0.3s;
    }

        .login-container input:focus {
            outline: none;
            border-color: #0FFCB3; /* Dopasowanie koloru obramowania po fokusie */
        }

    /* Button styling */
    .login-container button {
        padding: 10px 15px;
        font-size: 1rem;
        color: black;
        background-color: #0FFCB3; /* Dopasowanie koloru przycisku */
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-weight: bold;
        transition: background-color 0.3s, color 0.3s, transform 0.2s ease;
    }

        .login-container button:hover {
            background-color: #106EBE; /* Kolor po najechaniu */
            color: white; /* Zmiana koloru tekstu */
            transform: scale(1.05); /* Subtelne powiększenie */
        }
    /* Styl dla linku "Forgot your password?" */
    .login-container a {
        color: #0FFCB3; /* Dopasowanie koloru do przycisków i etykiet */
        text-decoration: none; /* Usuń podkreślenie */
        font-weight: bold; /* Pogrubienie tekstu */
        transition: color 0.3s ease;
    }

        .login-container a:hover {
            color: #106EBE; /* Zmiana koloru po najechaniu */
            text-decoration: underline; /* Podkreślenie tekstu przy hover */
        }

        .login-container a:focus {
            outline: none;
            color: #00D2A0; /* Podświetlenie po fokusie */
        }


/* Error message styling */
.error {
    color: #d9534f;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
}


/* Responsiveness */
@media (max-width: 768px) {
    .login-container {
        margin: 0 auto;
    }

        .login-container h2 {
            font-size: 1.5rem;
        }

        .login-container button {
            padding: 8px;
            font-size: 0.9rem;
        }

        .login-container input {
            padding: 8px;
            font-size: 0.9rem;
        }

    .login-form{
        max-width: 100vw;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 50px;
    }
}
