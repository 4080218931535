.time-planner-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #106EBE;
    background-image: linear-gradient(135deg ,#1E3D65 , #106EBE);
    color: white;
    min-height: 100vh;
    padding: 0;
    text-align: center;
    position: relative;
}

/* Style specific to TimePlanner */
.mantine-date-picker,
.mantine-time-picker {
    margin-bottom: 20px;
}

.calendar-container {
    margin: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Align arrows and month display in a single row */
.mantine-DatePicker-calendarHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px; /* Space between arrows and month label */
    margin-bottom: 20px;
}

.mantine-DatePicker-calendarHeaderLevel {
    font-size: 18px;
    font-weight: bold;
    color: white;
    background-color: #0FFCB3;
    padding: 10px 20px;
    border-radius: 25px; /* Elliptical shape for the month display */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Stylizowanie kontenerów strzałek */
.mantine-DatePicker-calendarHeaderControl {
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Kulki */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0FFCB3;
    color: black;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    position: relative;
}

    /* Obracanie SVG wewnątrz kontenerów */
    .mantine-DatePicker-calendarHeaderControl svg {
        transform: rotate(-90deg); /* Prawa strzałka domyślnie */
    }

    /* Lewa strzałka */
    .mantine-DatePicker-calendarHeaderControl:first-child svg {
        transform: rotate(90deg); /* Lewa strzałka */
    }


/* Calendar days styling */
.mantine-DatePicker-day {
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Circular days */
    background-color: #0FFCB3;
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

    .mantine-DatePicker-day:hover {
        background-color: #ddd;
        transform: scale(1.1);
    }

    .mantine-DatePicker-day.selected {
        background-color: black;
        color: white;
    }

/* Time slot buttons */
.time-slot-button {
    background-color: #0FFCB3;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 5px; /* Keep square with slightly rounded corners */
    cursor: pointer;
    transition: background-color 0.3s;
    flex: 1 1 100px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

    .time-slot-button:hover {
        background-color: #ddd;
    }

    .time-slot-button.selected {
        background-color: black;
        color: white;
    }

.time-slots-container {
    display: grid; /* Użycie grid zamiast flex */
    gap: 10px; /* Odstępy między slotami */
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Automatyczny podział kolumn */
    grid-auto-rows: auto; /* Automatyczne wysokości wierszy */
    width: 100%; /* Umożliwienie dynamicznego rozkładu */
    max-width: 800px; /* Opcjonalnie, maksymalna szerokość kontenera */
}

.time-slot-button {
    padding: 8px;
    margin: 4px;
    cursor: pointer;
}

    .time-slot-button.selected {
        background-color: #007bff;
        color: #fff;
    }

/* Styl podstawowy dla przycisków filtra */
.filter-button {
    background-color: #f0f0f0; /* Domyślny kolor przycisku */
    border: 1px solid #ccc;
    color: #333;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

    /* Styl dla aktywnego przycisku */
    .filter-button.active {
        background-color: #007bff; /* Kolor aktywnego przycisku */
        color: #fff; /* Biały tekst */
        border: 1px solid #0056b3;
        font-weight: bold;
    }

    /* Styl hover */
    .filter-button:hover {
        background-color: #e0e0e0;
    }
/* Styl podstawowy dla przycisków dnia */
.day-slider {
    display: flex;
    flex-direction: row; /* Wyświetlanie w wierszu */
    justify-content: center; /* Wyśrodkowanie elementów */
    align-items: center; /* Wyrównanie w pionie */
    gap: 10px; /* Odstępy między przyciskami */
    margin: 10px 0; /* Odstępy nad i pod sliderem */
}

.day-button {
    display: flex;
    flex-direction: column; /* Tekst w dwóch liniach */
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
    color: black;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 14px;
}

    .day-button.active {
        background-color: #007bff;
        color: #fff;
        border: 1px solid #0056b3;
        font-weight: bold;
        transform: scale(1.1);
    }

    .day-button:hover {
        background-color: #e0e0e0;
    }

/* Style dla ekranów o szerokości 768px i mniejszych */
@media (max-width: 768px) {

    .day-slider {
        flex-wrap: wrap; /* Ustawienie elementów w wielu liniach */
        gap: 5px; /* Mniejsze odstępy między przyciskami */
    }

    .day-button {
        font-size: 12px; /* Zmniejszenie rozmiaru czcionki */
        padding: 8px; /* Mniejsze odstępy wewnętrzne */
    }

    .time-slots-container {
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)); /* Więcej kolumn na mniejszych ekranach */
        gap: 5px; /* Mniejsze odstępy między slotami */
    }

    .time-slot-button {
        font-size: 12px; /* Zmniejszenie rozmiaru czcionki przycisków */
        padding: 6px; /* Mniejsze odstępy */
    }

    .filter-button {
        font-size: 12px; /* Zmniejszenie rozmiaru czcionki */
        padding: 8px; /* Dopasowanie odstępów */
    }

    .calendar-container {
        margin: 20px; /* Mniejsze marginesy wokół kalendarza */
    }

    .mantine-DatePicker-calendarHeaderLevel {
        font-size: 14px; /* Zmniejszenie rozmiaru tekstu */
        padding: 8px 16px; /* Dopasowanie odstępów */
    }

    .mantine-DatePicker-calendarHeaderControl {
        font-size: 18px; /* Zmniejszenie rozmiaru ikon */
        width: 30px; /* Mniejsza szerokość */
        height: 30px; /* Mniejsza wysokość */
    }

    .mantine-DatePicker-day {
        width: 30px; /* Mniejsza szerokość dnia */
        height: 30px; /* Mniejsza wysokość */
    }
}


