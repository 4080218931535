.job-address-container {
	margin-top: 30px; /* Ustawiony odpowiedni odstęp */
	display: flex;
	flex-direction: column;
	align-items: center; /* Wycentrowane elementy */
	max-width: 600px; /* Dopasowana szerokość */
	width: 100%; /* Responsywna szerokość */
	padding: 20px; /* Wewnętrzne odstępy */
	text-align: center; /* Wyrównanie do środka */
	border-radius: 10px; /* Zaokrąglone rogi */
}

	.job-address-container h2 {
		font-size: 32px; /* Dopasowana wielkość tekstu */
		margin-bottom: 20px; /* Odstęp poniżej */
		color: white; /* Kolor akcentowy */
	}

.job-address-form-group {
	margin-bottom: 15px; /* Odstęp pomiędzy polami */
	width: 100%; /* Dopasowana szerokość */
}

	.job-address-form-group label {
		display: block;
		font-weight: bold;
		margin-bottom: 5px;
		color: white; /* Kolor tekstu */
		text-align: left; /* Wyrównanie do lewej */
	}

	.job-address-form-group input,
	.job-address-form-group select {
		width: 100%;
		padding: 10px;
		border: none;
		border-radius: 5px;
		font-size: 1rem;
		background-color: white; /* Kolor wiersza */
		color: black; /* Kolor tekstu */
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	}

		.job-address-form-group input[type="checkbox"] {
			width: auto;
			margin-right: 10px;
		}

		.job-address-form-group input:focus,
		.job-address-form-group select:focus {
			outline: none;
			border: 2px solid #e63946; /* Kolor obramowania w stanie aktywnym */
		}

.job-address-checkbox-group {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: 15px;
	color: white; /* Kolor tekstu */
}
