.estimate-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
}

    .estimate-details h2 {
        font-size: 24px;
        color: white; /* Kolor akcentowy */
        margin-bottom: 20px;
    }

    /* Pole formularza */
    .estimate-details label {
        display: block;
        text-align: left;
        margin-bottom: 5px;
        color: white; /* Subtelny kolor tekstu */
        font-size: 0.9rem;
    }

    .estimate-details p {
        font-size: 1rem;
        color: white;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .estimate-details .cost-breakdown {
        font-size: 0.8rem; /* Smaller font size */
        color: white; /* Subtle color matching 'Would you like to adjust details' */
        margin-top: 10px;
    }

.cost-breakdown-title {
    font-weight: bold;
    margin-bottom: 5px;
    color: white; /* Match color to the rest of the text */
}


.estimate-details textarea {
    width: 100%;
    padding: 20px 50px;
    border: none;
    border-radius: 0px;
    font-size: 1rem;
    background-color: white;
    color: black;
    margin-bottom: 0px;
    line-height: 1.5;
    resize: none; /* Disable manual resizing */
    overflow: hidden; /* Hide scrollbar */
}

    .estimate-details textarea:disabled {
        background-color: #2D3A4E; /* Color for disabled field */
    }


.estimate-details textarea{
    width: 100%;
}

@media (max-width: 768px) {
    .estimate-details{
        width: 80vw;
    }

    .estimate-details textarea{
        width: 60%;
        margin-left: 10px;
        margin-right: 10px;
    }

}