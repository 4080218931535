.estimate-generator-page {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #106EBE;
    background-image: linear-gradient(135deg ,#1E3D65 , #106EBE);
    min-height: 100vh;
    color: white; /* Tekst w kolorze białym */
    padding: 0;
}

/* Sekcja generatora */
.estimate-generator {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    width: 100%;
    padding: 20px;
    text-align: center;
    background-color: transparent; /* Dopasowany kolor */
    border-radius: 10px;
    margin-bottom: 20px;
}

.estimate-generator__left{
    display: flex;
    flex-direction: column;
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.estimate-generator__right{
    display: flex;
    flex-direction: column;
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.estimate-generator__container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw;
    margin-top: 20px;
    justify-items: center;
}

.estimate-title {
    font-size: 32px;
    color: white; /* Kolor akcentowy */
    margin-bottom: 20px;
}

.estimate-instruction {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
    color: #ccc; /* Subtelny kolor instrukcji */
}

/* Pole tekstowe */
.estimate-input {
    width: 100%;
    height: 100px;
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    resize: vertical;
    background-color: white; /* Kolor tła pola */
    color: black; /* Kolor tekstu */
}

    .estimate-input::placeholder {
        color: #ccc; /* Kolor tekstu zastępczego */
    }

/* Przyciski */
.generate-button {
    background-color: #0FFCB3;
    color: black;
    padding: 20px 150px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

    .generate-button:hover {
        background-color: black;
        color: white;
        transform: scale(1.05); /* Subtelne powiększenie przy najechaniu */
    }

.proceed-button {
    background-color: #0FFCB3;
    color: black;
    padding: 20px 250px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

    .proceed-button:hover {
        background-color: black;
        color: white;
        transform: scale(1.05);
    }


@media (max-width: 1024px) {
    .estimate-generator{
        width: 80vw;
    }
    .generate-button{
        width: 85vw;
        margin-left: 10px;
    }

    .estimate-generator__left{
        width: 80vw;
    }

    .estimate-generator__left textarea{
        width: 60%;
        margin-left: 10px;
        margin-right: 10px;
    }

    .estimate-generator__right{
        width: 80vw;
    }

    .estimate-generator__right input{
        margin-left: 10px;
        margin-right: 10px;
        width: 90%;
    }

    .proceed-button{
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 10px;
        padding: 20px 20px;
        width: 90vw;
    }
}