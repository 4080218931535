.order-summary {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    background-color: #1E3D65;
    background-image: linear-gradient(135deg ,#1E3D65 , #106EBE);
    color: white;
    min-height: 100vh;
    text-align: center;
    width: 100%;

}

.order-summary__container{
    width: 98vw;
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.summary-section {
    min-width: 565px;
    height: 350px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-content: center;
    text-align: center;
}

.order-status{
    display: block;
    flex-direction: row;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
}

.summary-section button{
    margin-top: 20px;
    width: 230px;
}

h2 {
    text-align: center;
    margin: 0 auto;
    flex-grow: 1;
}

p {
    margin: 5px 0;
}

/* Responsywne style dla mniejszych ekranów */
@media (max-width: 768px) {
    .summary-section {
        max-width: 90%;
        padding: 15px;
        min-width: 405px;
    }


    .summary-section {

    }
 
}

@media (max-width: 480px) {
    .summary-section {
        padding: 0;
        min-width: 395px;
        margin-left: auto;
        margin-right: auto;
    }

    .order-summary {
        padding: 0;
    }

    h2 {
        font-size: 1.5rem;
    }

}
