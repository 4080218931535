/* Sekcja Invoice Details */
.invoice-section {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 0; /* Ten sam niebieski kolor co w Job Details */
}

.invoice-form{
    background-color: #106EBE;
    background-image: linear-gradient(135deg ,#1E3D65 , #106EBE);
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;

}

    /* Tytuł wewnątrz sekcji */
    .invoice-section h2 {
        font-size: 32px; /* Wielkość tekstu */
        margin-bottom: 20px;
        color: white; /* Biały kolor tekstu */
        text-align: center;
    }

    /* Formularz w sekcji */
    .invoice-section form {
        width: 100%;
    }

    .invoice-section label {
        display: block;
        text-align: left;
        font-weight: bold;
        margin-bottom: 5px;
        color: white; /* Biały kolor etykiet */
    }

    .invoice-section input {
        width: 100%; /* Pełna szerokość */
        padding: 10px;
        border: none;
        border-radius: 5px; /* Zaokrąglone rogi */
        font-size: 1rem; /* Rozmiar czcionki */
        margin-bottom: 15px;
        background-color: white; /* Białe tło pól */
        color: black; /* Czarny kolor tekstu */
    }

/* Responsywność */
@media (max-width: 768px) {
    .invoice-section {
        padding: 15px; /* Mniejsze odstępy */
    }

        .invoice-section h2 {
            font-size: 24px; /* Zmniejszenie rozmiaru tytułu */
        }

        .invoice-section input {
            font-size: 0.9rem; /* Mniejsze pola */
            padding: 8px;
        }
}

@media (max-width: 480px) {
    .invoice-section {
        max-width: 90%; /* Dopasowanie szerokości */
    }

        .invoice-section h2 {
            font-size: 20px; /* Jeszcze mniejszy tytuł */
        }

        .invoice-section input {
            font-size: 0.8rem; /* Dopasowanie do małych ekranów */
            padding: 6px;
        }
}
