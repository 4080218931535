/* src/components/Navbar.css */
.navbar{
    display: flex;
    justify-content: space-between;
    background-color: #333;
    color: white;
    padding: 10px 20px;
}

.back-button, .next-button {
    background-color: #0FFCB3;
    color: black;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.welcome-message {
    margin-right: auto;
    margin-left: 20px;
    font-size: 16px;
}


@media (max-width: 480px) {
    .welcome-message {
        margin-right: 0;
        margin-left: 0;
        width: 0;
        visibility: hidden;
    }
}
