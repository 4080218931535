/* src/components/AdminPortal/AdminPortal.css */

.customer-portal {
    background-color: #106EBE;
    background-image: linear-gradient(135deg ,#1E3D65 , #106EBE);
    text-align: center;
    min-height: 100vh;
    color: white;
    justify-items: center;
}

.customer-portal h2 {
    font-size: 24px;
    color: white;
}

.customer-portal nav ul {
    list-style: none;
    padding: 0;
    color: white;
}

.customer-portal nav ul li {
    margin: 5px 0;
    color: white;
}

.customer-portal .customer-content {
    margin-top: 20px;
}

.customer-portal nav ul li a {
    color: white;
    text-decoration: none;
}

.customer-portal nav ul li a:visited {
    color: white;

}

.customer-portal nav ul li a:hover {
    color: #0FFCB3;

}
