body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
}


.manage_container{
    background-color: #106EBE;
    background-image: linear-gradient(135deg ,#1E3D65 , #106EBE);
    text-align: center;
    min-height: 100vh;
    color: white;
    justify-items: center;
}


.manage_container h2 {
    color: white;
}



button {
    font-size: 14px;
    padding: 8px 16px;
}

.card {
    margin-bottom: 20px;
    padding: 20px;
}

.table th {
    font-weight: bold;
    color: #555;
}

.table td {
    text-align: center;
}
