.welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #106EBE;
    background-image: linear-gradient(135deg ,#1E3D65 , #106EBE);
    color: white;
    min-height: 100vh;
    text-align: center;
    padding: 0; /* Zerowanie paddingu, jeśli istnieje */
    margin: 0; /* Zerowanie marginesów */
}


/* Sekcja z boxem */
.blue-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent; /* Kolor tła boxu */
    color: white; /* Kolor tekstu w boxie */
    max-width: 70%; /* Szerokość boxu */
    padding: 0px;
    border-radius: 15px; /* Zaokrąglenie krawędzi */
    margin-bottom: 20px; /* Odstęp między boxem a resztą */
    margin-top: 20px;
}

/* Stylizacja logo w boxie */
.logo img {
    margin: 0 auto;
    width: 350px; /* Dopasuj rozmiar loga */
    margin-bottom: 20px;
}

/* Tytuł w boxie */
.title {
    font-size: 1.5rem;
    margin-bottom: 20px;
}
.instruction-list {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
}

.instruction-step {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.step-button {
    background: none;
    border: none;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    transition: color 0.3s;
    text-align: left;
}

    .step-button:hover {
        color: #00e0ff;
    }

    .step-button .circle {
        background-color: #00c8ff;
        color: white;
        font-size: 1rem;
        font-weight: bold;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        transition: background-color 0.3s ease;
    }

    .step-button.active .circle {
        background-color: #00e0ff;
    }

.step-details {
    background-color: #1e3d65;
    color: white;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    font-size: 0.9rem;
    animation: fadeIn 0.3s ease-in-out;
}

/* Animacja pojawiania się szczegółów */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


/* Style dla ekranów o szerokości 768px i mniejszych */
@media (max-width: 768px) {
    .blue-box {
        max-width: 90%; /* Dostosowanie szerokości do małych ekranów */
        padding: 10px; /* Większy odstęp wewnętrzny */
    }

    .logo img {
        width: 200px; /* Zmniejszenie rozmiaru logo */
    }

    .service-options {
        flex-direction: column; /* Ułożenie ikonek w kolumnie */
    }

    .service-option:hover {
        background-color: #1E3D65; /* Zmiana koloru po najechaniu */
    }

        .service-option img {
            max-width: 50px; /* Zmniejszenie rozmiaru ikon */
            max-height: 50px;
        }

    .title {
        font-size: 1.2rem; /* Zmniejszenie rozmiaru czcionki */
    }
}
