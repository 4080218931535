/* GenerateInvoice.css */

.generate-invoice {
    background-color: #106EBE;
    background-image: linear-gradient(135deg ,#1E3D65 , #106EBE);
    text-align: center;
    min-height: 100vh;
    color: white;
    width: 100%;
}

h2 {
    text-align: center;
    margin-top: 40px;
    color: white;
}

.invoice-section {
    margin-bottom: 20px;
}

    .invoice-section h3 {
        font-size: 1.2em;
        margin-bottom: 10px;
        color: white;
        border-bottom: 1px solid #ddd;
        padding-bottom: 5px;
    }

    .invoice-section input, .invoice-section textarea {
        width: 80%;
        padding: 10px;
        margin-bottom: 10px;
        font-size: 1em;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    }

    .invoice-section textarea {
        resize: vertical;
        height: 80px;
    }

.item-row {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
}

    .item-row input {
        padding: 8px;
        font-size: 0.9em;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .item-row span {
        text-align: right;
        font-weight: bold;
    }

button {
    background-color: #0FFCB3;
    color: black;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
}

    button:hover {
        background-color: #106EBE; /* Niebieskie tło przy najechaniu */
        color: white; /* Biały tekst */
        transform: scale(1.05); /* Subtelne powiększenie */
    }

    button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }

.generate-invoice .total-section {
    font-size: 1.2em;
    font-weight: bold;
    text-align: right;
    margin-top: 20px;
    color: #333;
}
