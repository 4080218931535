
.register-container{
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #106EBE;
    background-image: linear-gradient(135deg ,#1E3D65 , #106EBE);
    min-height: 100vh;
    color: white; /* Tekst w kolorze białym */
    padding: 0;
}

.register-container h2{
    color: white;
}

.register_form{
    display: flex;
    flex-direction: column;
    width: 100vw;
}


@media (max-width: 768px) {
    .register_form input {
        width: auto;
        margin:0;
        padding: 8px;
        font-size: 0.9rem;
    }

    .register-container{
        margin:0 auto ;
    }

    .register_form form{
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .register_form{
        max-width: 96vw;
        margin-left: 10px;
        margin-right: 10px;
    }
    .register_form button{
        margin: 0;
        padding: 8px;
        font-size: 0.9rem;
    }
}